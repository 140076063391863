import React, { useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat';

const CrimeHeatmap = ({ coordinates }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const heatLayerRef = useRef(null);

  useEffect(() => {
    if (!mapInstanceRef.current && mapRef.current) {
      mapInstanceRef.current = L.map(mapRef.current).setView([51.509865, -0.118092], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors',
        maxZoom: 19
      }).addTo(mapInstanceRef.current);
    }

    if (coordinates && coordinates.length > 0) {
      if (heatLayerRef.current) {
        mapInstanceRef.current.removeLayer(heatLayerRef.current);
      }

      // Create points with much lower base intensity
      const points = coordinates.map(coord => ({
        lat: coord[0],
        lng: coord[1],
        intensity: 0.15 // Significantly reduced base intensity
      }));

      heatLayerRef.current = L.heatLayer(points, {
        radius: 12,     // Smaller initial radius
        blur: 15,       // Slightly increased blur for smoother appearance
        maxZoom: 16,    // Higher max zoom for more detail
        max: 0.6,       // Lower maximum intensity
        minOpacity: 0.35, // Minimum opacity to ensure visibility
        gradient: {
          0.4: 'blue',
          0.6: 'lime',
          0.8: 'yellow',
          1.0: 'red'
        }
      }).addTo(mapInstanceRef.current);

      // Dynamic radius and intensity based on zoom level
      mapInstanceRef.current.on('zoomend', () => {
        const currentZoom = mapInstanceRef.current.getZoom();
        const baseRadius = 12;
        const zoomFactor = Math.pow(1.2, currentZoom - 13); // Exponential scaling
        
        // Adjust radius based on zoom level
        const newRadius = Math.min(30, baseRadius * zoomFactor);
        
        heatLayerRef.current.setOptions({
          radius: newRadius,
          blur: newRadius * 1.25,
          max: Math.min(1.0, 0.6 + (currentZoom - 13) * 0.1) // Gradually increase intensity with zoom
        });
      });

      const bounds = L.latLngBounds(coordinates);
      mapInstanceRef.current.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 14     // Limit initial zoom to prevent too much detail
      });
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, [coordinates]);

  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle>Crime Heatmap</CardTitle>
      </CardHeader>
      <CardContent>
        <div ref={mapRef} className="h-96 w-full rounded-lg overflow-hidden" />
        <p className="mt-4 text-sm text-gray-600">
          This heatmap shows the distribution of reported crimes. 
          Blue areas indicate lower concentrations, while yellow and red show higher concentrations. 
          Zoom in to see more detailed patterns in specific areas.
        </p>
      </CardContent>
    </Card>
  );
};

export default CrimeHeatmap;