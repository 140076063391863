import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Map, TrendingUp, Users } from 'lucide-react';

const HomePage = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: <Map className="h-6 w-6 text-blue-600" />,
      title: "Location-Based Analysis",
      description: "Enter any UK postcode to get detailed crime statistics for that specific area."
    },
    {
      icon: <TrendingUp className="h-6 w-6 text-blue-600" />,
      title: "Historical Trends",
      description: "View crime rate trends over time with interactive charts and visualizations."
    },
    {
      icon: <Users className="h-6 w-6 text-blue-600" />,
      title: "City Comparisons",
      description: "Compare local crime rates with city averages to understand your area better."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Understanding Crime Statistics in Your Area
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          Make informed decisions about your neighborhood with our comprehensive crime statistics analysis tool.
        </p>
        <button
          onClick={() => navigate('/crime-stats')}
          className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-lg"
        >
          Get Started
        </button>
      </div>

      {/* Features Grid */}
      <div className="grid md:grid-cols-3 gap-8 mb-16">
        {features.map((feature, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
            <div className="mb-4">{feature.icon}</div>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              {feature.title}
            </h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>

      {/* How It Works Section */}
      <div className="bg-white rounded-lg shadow-sm p-8 mb-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">
          How It Works
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-2xl font-bold text-blue-600 mb-2">1</div>
            <h3 className="text-lg font-semibold mb-2">Enter Your Postcode</h3>
            <p className="text-gray-600">Simply input any UK postcode to begin your search</p>
          </div>
          <div className="text-center">
            <div className="text-2xl font-bold text-blue-600 mb-2">2</div>
            <h3 className="text-lg font-semibold mb-2">View Statistics</h3>
            <p className="text-gray-600">Get instant access to detailed crime data and trends</p>
          </div>
          <div className="text-center">
            <div className="text-2xl font-bold text-blue-600 mb-2">3</div>
            <h3 className="text-lg font-semibold mb-2">Compare Data</h3>
            <p className="text-gray-600">See how your area compares to city averages</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

