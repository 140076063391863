import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const formatCrimeType = (type) => {
  return type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const formatCityName = (city) => {
  return city
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const PercentileChart = ({ percentileRanks, city }) => {
  const data = Object.entries(percentileRanks)
    .map(([crimeType, percentile]) => ({
      crimeType: formatCrimeType(crimeType),
      percentile: percentile,
    }))
    .sort((a, b) => b.percentile - a.percentile);

  const cityName = formatCityName(city);

  return (
    <Card className="w-full mb-8">
      <CardHeader>
        <CardTitle>{cityName} Crime Rate Percentiles (Average Across Years)</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 150, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 100]} />
              <YAxis dataKey="crimeType" type="category" />
              <Tooltip 
                formatter={(value) => [`${value}th percentile`, "Rank"]}
              />
              <Bar
                dataKey="percentile"
                fill="#2563eb"
                label={{ position: 'right', formatter: (value) => `${value}%` }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <p className="mt-4 text-sm text-gray-600">
          These percentiles show how your area's average crime rates compare to other areas in {cityName} across all years. 
          A higher percentile indicates more reported crimes compared to other areas. 
          For example, the 90th percentile means the area has more reported crimes than 90% of similar areas.
        </p>
      </CardContent>
    </Card>
  );
};

export default PercentileChart;